<template>
  <b-card title="Schema">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span> Schema Course General</span>
        </template>
        <AddSchema/>
      </b-tab>
       <b-tab @click="$router.push('/add-schema-classic')"  >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> Add Course Classic</span>
        </template>
        <ClassicalSchema  v-if="currentRouteName=='add-schema-classic'"  @add-course="handleAddCoursClassic"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
       <b-tab @click="$router.push('/add-schema-online')" >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> Add Course Online</span>
        </template>
        
        <OnlineSchema v-if="currentRouteName=='add-schema-online'" @add-course="handleAddCourse"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>

       <b-tab @click="$router.push('/classic-schema')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Classical Schema List</span>
        </template>
       <ClassicalCourse ref="ClassicList"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
      
       <b-tab @click="$router.push('/online-schema')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Online Schema List</span>
        </template>
       <OnlineCourse ref="OnlineList"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import AddSchema from './AddSchema.vue'
import ClassicalSchema from './ClassicalSchema.vue'
import OnlineSchema from './OnlineSchema.vue'
import ClassicalCourse from './ClassicalCourse.vue'
import OnlineCourse from './OnlineCourse.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AddSchema,
    ClassicalSchema,
     OnlineCourse,
      ClassicalCourse,
    OnlineSchema
  },
  data() {
    return {

    }
  },
   computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
    methods: {
    handleAddCourse() {
      this.$refs.OnlineList.GetAllPosts() // Call getAllPost function in SchemaFaq component
    },
     handleAddCoursClassic() {
      this.$refs.ClassicList.GetAllPosts() // Call getAllPost function in SchemaFaq component
    }
  }
}
</script>
