var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add Schema Online "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Category","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.AllcategoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.addCourseForm.category)}},model:{value:(_vm.addCourseForm.category),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "category", $$v)},expression:"addCourseForm.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Schema","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"cate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.categoriesList},on:{"input":function($event){return _vm.getSchema(_vm.addCourseForm.schemaable_id)}},model:{value:(_vm.addCourseForm.schemaable_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "schemaable_id", $$v)},expression:"addCourseForm.schemaable_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Name","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"COURSE-NAME"},model:{value:(_vm.courseData.name),callback:function ($$v) {_vm.$set(_vm.courseData, "name", $$v)},expression:"courseData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Category","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"cate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"COURSE-LOGO"},model:{value:(_vm.courseData.category),callback:function ($$v) {_vm.$set(_vm.courseData, "category", $$v)},expression:"courseData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Location Name","label-for":"location-name"}},[_c('validation-provider',{attrs:{"name":"locationName","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"LOCATION-name"},model:{value:(_vm.courseData.location.name),callback:function ($$v) {_vm.$set(_vm.courseData.location, "name", $$v)},expression:"courseData.location.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Aggregate Rating","label-for":"aggregate-rating"}},[_c('b-form-input',{attrs:{"id":"aggregate-rating","type":"number"},model:{value:(_vm.courseData.aggregateRating.ratingValue),callback:function ($$v) {_vm.$set(_vm.courseData.aggregateRating, "ratingValue", $$v)},expression:"courseData.aggregateRating.ratingValue"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Aggregate Rating Count","label-for":"aggregate-rating"}},[_c('b-form-input',{attrs:{"id":"aggregate-rating","type":"number"},model:{value:(_vm.courseData.aggregateRating.ratingCount),callback:function ($$v) {_vm.$set(_vm.courseData.aggregateRating, "ratingCount", $$v)},expression:"courseData.aggregateRating.ratingCount"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Aggregate Review Count","label-for":"aggregate-rating"}},[_c('b-form-input',{attrs:{"id":"aggregate-rating","type":"number"},model:{value:(_vm.courseData.aggregateRating.reviewCount),callback:function ($$v) {_vm.$set(_vm.courseData.aggregateRating, "reviewCount", $$v)},expression:"courseData.aggregateRating.reviewCount"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Offer Type","label-for":"offer-type"}},[_c('validation-provider',{attrs:{"name":"offerType","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"OFFER-@type"},model:{value:(_vm.courseData.offers['@type']),callback:function ($$v) {_vm.$set(_vm.courseData.offers, '@type', $$v)},expression:"courseData.offers['@type']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Offer Price","label-for":"offer-price"}},[_c('validation-provider',{attrs:{"name":"offerPrice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"OFFER-price"},model:{value:(_vm.courseData.offers.price),callback:function ($$v) {_vm.$set(_vm.courseData.offers, "price", $$v)},expression:"courseData.offers.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Offer Category","label-for":"offer-price"}},[_c('validation-provider',{attrs:{"name":"offerPrice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"OFFER-price"},model:{value:(_vm.courseData.offers.category),callback:function ($$v) {_vm.$set(_vm.courseData.offers, "category", $$v)},expression:"courseData.offers.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"provider Type","label-for":"course-images"}},[_vm._v(" Organization ")])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"provider Name","label-for":"course-images"}},[_c('b-form-input',{attrs:{"id":"provider-name"},model:{value:(_vm.courseData.provider.name),callback:function ($$v) {_vm.$set(_vm.courseData.provider, "name", $$v)},expression:"courseData.provider.name"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"provider URL","label-for":"course-images"}},[_c('b-form-input',{attrs:{"id":"provider-url"},model:{value:(_vm.courseData.provider.url),callback:function ($$v) {_vm.$set(_vm.courseData.provider, "url", $$v)},expression:"courseData.provider.url"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" Loading... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }